export default {
  login: "",
  site: "",
  uppercase: true,
  lowercase: true,
  numbers: true,
  symbols: true,
  length: 16,
  counter: 1,
  version: 2
};
