export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const SET_DEFAULT_OPTIONS = "SET_DEFAULT_OPTIONS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORDS = "SET_PASSWORDS";
export const SET_TOKENS = "SET_TOKENS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_SITE = "SET_SITE";
export const DELETE_PASSWORD = "DELETE_PASSWORD";
export const CLEAN_MESSAGE = "CLEAN_MESSAGE";
