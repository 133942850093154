<style>
#fingerprint {
  min-width: 90px;
  text-align: center;
  background-color: transparent;
  color: white;
}

#fingerprint i {
  color: black;
  position: relative;
  padding: 0;
  text-shadow: 1px 1px 0 white;
  font-size: 1.3em;
}
</style>
<template>
  <div class="masterPassword">
    <div class="input-group inner-addon left-addon">
      <label for="passwordField" class="sr-only">{{ label }}</label>
      <i class="fa fa-lock"></i>
      <input
        id="passwordField"
        name="passwordField"
        ref="passwordField"
        v-bind:type="passwordType"
        class="form-control"
        tabindex="0"
        autocorrect="off"
        autocapitalize="none"
        v-bind:value="value"
        v-bind:placeholder="label"
        v-on:input="updateValue($event.target.value)"
        v-on:keyup.enter="$emit('keyupEnter')"
      />
      <span
        class="input-group-btn"
        v-if="fingerprint && value"
        v-on:click="togglePasswordType"
      >
        <button id="fingerprint" class="btn" type="button" tabindex="-1">
          <small>
            <i
              class="fa fa-fw"
              v-bind:class="[icon1]"
              v-bind:style="{ color: color1 }"
            ></i>
            <i
              class="fa fa-fw"
              v-bind:class="[icon2]"
              v-bind:style="{ color: color2 }"
            ></i>
            <i
              class="fa fa-fw"
              v-bind:class="[icon3]"
              v-bind:style="{ color: color3 }"
            ></i>
          </small>
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import LessPass from "lesspass";
import { debounce } from "lodash";

export default {
  name: "masterPassword",
  props: {
    value: String,
    label: String
  },
  data() {
    return {
      passwordType: "password",
      fingerprint: null,
      icon1: "",
      icon2: "",
      icon3: "",
      color1: "",
      color2: "",
      color3: ""
    };
  },
  methods: {
    updateValue(newPassword) {
      const fakePassword = Math.random()
        .toString(36)
        .substring(7);
      this.setFingerprint(fakePassword);
      this.showRealFingerprint(newPassword);
      this.$refs.passwordField.value = newPassword;
      this.$emit("input", newPassword);
    },
    togglePasswordType() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    hide() {
      this.passwordType = "password";
      this.fingerprint = null;
    },
    getColor(color) {
      var colors = [
        "#000000",
        "#074750",
        "#009191",
        "#FF6CB6",
        "#FFB5DA",
        "#490092",
        "#006CDB",
        "#B66DFF",
        "#6DB5FE",
        "#B5DAFE",
        "#920000",
        "#924900",
        "#DB6D00",
        "#24FE23"
      ];
      var index = parseInt(color, 16) % colors.length;
      return colors[index];
    },
    getIcon(hash) {
      var icons = [
        "fa-hashtag",
        "fa-heart",
        "fa-hotel",
        "fa-university",
        "fa-plug",
        "fa-ambulance",
        "fa-bus",
        "fa-car",
        "fa-plane",
        "fa-rocket",
        "fa-ship",
        "fa-subway",
        "fa-truck",
        "fa-jpy",
        "fa-eur",
        "fa-btc",
        "fa-usd",
        "fa-gbp",
        "fa-archive",
        "fa-area-chart",
        "fa-bed",
        "fa-beer",
        "fa-bell",
        "fa-binoculars",
        "fa-birthday-cake",
        "fa-bomb",
        "fa-briefcase",
        "fa-bug",
        "fa-camera",
        "fa-cart-plus",
        "fa-certificate",
        "fa-coffee",
        "fa-cloud",
        "fa-coffee",
        "fa-comment",
        "fa-cube",
        "fa-cutlery",
        "fa-database",
        "fa-diamond",
        "fa-exclamation-circle",
        "fa-eye",
        "fa-flag",
        "fa-flask",
        "fa-futbol-o",
        "fa-gamepad",
        "fa-graduation-cap"
      ];
      var index = parseInt(hash, 16) % icons.length;
      return icons[index];
    },
    setFingerprint(password) {
      LessPass.createFingerprint(password).then(fingerprint => {
        this.icon1 = fingerprint[0].icon;
        this.color1 = fingerprint[0].color;

        this.icon2 = fingerprint[1].icon;
        this.color2 = fingerprint[1].color;

        this.icon3 = fingerprint[2].icon;
        this.color3 = fingerprint[2].color;

        this.fingerprint = fingerprint;
      });
    },
    showRealFingerprint: debounce(function(password) {
      this.setFingerprint(password);
    }, 500)
  }
};
</script>
